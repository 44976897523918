import React from "react";
import "./TermsAndConditions.css";
const TermsAndConditions = () => {
  return (
    <>
      <section
        className="main-banner-in terms_banner"
        style={{ backgroundImage: "url(/assets/images/banner-3.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="banner-in-title wow fadeInUp  animated text-center"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInUp",
                }}
              >
                <h1 className="h1-title">
                  CiteEcho Terms and Conditions for Visual Abstract Submissions
                </h1>
                <p className="p-md">
                  Effective Date: <span>24th April 2024</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="terms-page" className="pb-100 inner-page-hero division">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="txt-block legal-info">
                <p>
                  Thank you for using CiteEcho! By submitting a visual abstract
                  (<span>"Content"</span>) to CiteEcho, you agree to the
                  following terms and conditions (<span>"Terms"</span>). If you
                  do not agree, please refrain from submitting any Content.
                </p>
              </div>
              <div className="txt-block legal-info">
                <h5 className="h5-md">
                  <span>1.</span> Eligibility
                </h5>
                <p>By submitting Content, you represent and warrant that: </p>
                <p>
                  <span className="h6-lg me-2">1.1.</span>
                  You are at least 18 years old or have obtained parental or
                  guardian consent if you are a minor.
                </p>
                <p>
                  <span className="h6-lg me-2">1.2.</span> You have all
                  necessary rights, permissions, and authority to submit the
                  Content and agree to these Terms.
                </p>

                <h5 className="h5-md">
                  <span>2.</span> Ownership of Content
                </h5>

                <p>
                  <span className="h6-lg me-2">2.1.</span>
                  You retain ownership of any intellectual property rights you
                  hold in the Content you submit.
                </p>
                <p>
                  <span className="h6-lg me-2">2.2.</span> By submitting
                  Content, you grant CiteEcho a worldwide, non-exclusive,
                  royalty-free, sublicensable, and transferable license to use,
                  reproduce, distribute, modify, display, and perform the
                  Content in connection with the CiteEcho platform and related
                  promotional activities.
                </p>

                <h5 className="h5-md">
                  <span>3.</span> Responsibility for Content
                </h5>

                <p>
                  <span className="h6-lg me-2">3.1.</span>
                  You are solely responsible for the accuracy, originality, and
                  legality of the Content you submit.
                </p>
                <p>
                  <span className="h6-lg me-2">3.2.</span>
                  You warrant that:
                </p>

                <ul className="simple-list ms-2 ms-md-5">
                  <li className="list-item">
                    <p>
                      The Content does not infringe upon or violate any
                      copyright, trademark, privacy, or other proprietary rights
                      of any third party.
                    </p>
                  </li>
                  <li className="list-item">
                    <p>
                      The Content does not contain defamatory, obscene, or
                      unlawful material.
                    </p>
                  </li>
                </ul>
                <h5 className="h5-md">
                  <span>4.</span> Prohibited Submissions
                </h5>
                <div className="d-flex">
                  <p>
                    <span className="h6-lg me-2">4.1.</span>
                    You agree not to submit Content that:
                  </p>
                </div>
                <ul className="simple-list ms-2 ms-md-5">
                  <li className="list-item">
                    <p>Is fraudulent, deceptive, or misleading.</p>
                  </li>

                  <li className="list-item">
                    <p>Contains viruses, malware, or other harmful elements.</p>
                  </li>
                  <li className="list-item">
                    <p>Violates applicable laws or regulations.</p>
                  </li>
                </ul>

                <h5 className="h5-md">
                  <span>5.</span> Content Review and Removal
                </h5>
                <p>
                  <span className="h6-lg me-2">5.1.</span>
                  CiteEcho reserves the right to review, edit, or remove any
                  submitted Content at its sole discretion, including for
                  violations of these Terms.
                </p>
                <p>
                  <span className="h6-lg me-2">5.2.</span>
                  CiteEcho is not obligated to pre-screen or monitor submitted
                  Content but may do so at its discretion.
                </p>

                <h5 className="h5-md">
                  <span>6.</span> Indemnification
                </h5>
                <p>
                  You agree to indemnify, defend, and hold harmless CiteEcho,
                  its affiliates, employees, and partners from any claims,
                  damages, or liabilities arising from:
                </p>
                <p>
                  <span className="h6-lg me-2">6.1.</span>
                  Your submission of Content.
                </p>
                <p>
                  <span className="h6-lg me-2">6.2.</span>
                  Your violation of these Terms.
                </p>
                <p>
                  <span className="h6-lg me-2">6.3.</span>
                  Your infringement of any third-party rights.
                </p>

                <h5 className="h5-md">
                  <span>7.</span> Limitation of Liability
                </h5>
                <p>
                  To the fullest extent permitted by law, CiteEcho is not liable
                  for:
                </p>
                <p>
                  <span className="h6-lg me-2">7.1.</span>
                  Loss or damage resulting from the use, display, or
                  distribution of submitted Content.
                </p>
                <p>
                  <span className="h6-lg me-2">7.2.</span>
                  Any indirect, incidental, or consequential damages arising
                  from your submission.
                </p>
                <h5 className="h5-md">
                  <span>8.</span> Confidentiality and Privacy
                </h5>
                <p>
                  <span className="h6-lg me-2">8.1.</span>
                  CiteEcho takes the protection of your personal data seriously.
                  Please refer to our Privacy Policy for details on how we
                  handle personal information.
                </p>
                <p>
                  <span className="h6-lg me-2">8.2.</span>
                  You acknowledge that submitted Content may be publicly
                  accessible and agree not to submit confidential or proprietary
                  information.
                </p>

                <h5 className="h5-md">
                  <span>9.</span> Modifications to Terms
                </h5>
                <p>
                  CiteEcho reserves the right to update or modify these Terms at
                  any time. Changes will be effective upon posting on the
                  CiteEcho platform. Continued submission of Content constitutes
                  acceptance of the revised Terms.
                </p>

                <h5 className="h5-md">
                  <span>10.</span> Governing Law and Dispute Resolution
                </h5>
                <p>
                  <span className="h6-lg me-2">10.1.</span>
                  These Terms are governed by the laws of Ontario.
                </p>
                <p>
                  <span className="h6-lg me-2">10.2.</span>
                  Any disputes arising under these Terms will be resolved
                  exclusively in the courts of Ontario.
                </p>

                <h5 className="h5-md">
                  <span>11.</span> Termination
                </h5>
                <p>
                  CiteEcho reserves the right to terminate your access to the
                  platform or revoke the license granted under these Terms if
                  you violate these Terms or engage in unlawful activities.
                </p>

                <h5 className="h5-md">
                  <span>10.</span> Contact Information
                </h5>
                <p className="mb-0">
                  For questions or concerns about these Terms, please contact us
                  at: Email
                  <a
                    href="mailto:admin@citeecho.com"
                    className="color--theme ms-2"
                  >
                    admin@citeecho.com
                  </a>
                </p>
                <p>
                  By submitting Content, you acknowledge that you have read,
                  understood, and agree to these Terms and Conditions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditions;
