import React, { useState, useEffect, useContext } from "react";
import { useApi } from "../../context/ApiContext";
import "./create-project.css";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { AuthContext } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import { FaPlus, FaPlusCircle, FaTimesCircle } from "react-icons/fa";

function convertToEmbedUrl(url) {
  const patterns = [
    /^https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/,
    /^https:\/\/youtu\.be\/([a-zA-Z0-9_-]{11})/,
    /^https:\/\/www\.youtube\.com\/embed\/([a-zA-Z0-9_-]{11})/,
    /^https:\/\/www\.youtube\.com\/v\/([a-zA-Z0-9_-]{11})/,
  ];
  for (const pattern of patterns) {
    const match = pattern.exec(url);
    if (match) {
      return `https://www.youtube.com/embed/${match[1]}`;
    }
  }
  return null;
}

const CreateProject = () => {
  const [affiliation, setAffiliation] = useState([]);
  const [myprojects, setMyProjects] = useState([]);
  const [institution_id, setInstitution_id] = useState();
  const [video_abstract, setVideo_abstract] = useState();
  const [visual_abstract, setVisual_abstract] = useState();
  const [collaboratortype, setCollaboratorType] = useState([]);
  // const [collaboratoraffiliation, setCollaboratorAffiliation] = useState([]);
  const [formBusy, setFormBusy] = useState(false);
  const [agree, setAgree] = useState("");
  const { apiEndpoints, fetchApi } = useApi();
  const { token, user, Logout } = useContext(AuthContext);
  const [entryType, setEntryType] = useState("1");
  const [title, setTitle] = useState("");
  const [show, setShow] = useState(false);
  const [collaborations, setCollaborations] = useState([
    {
      collaboratortype: "",
      collaboratoraffiliation: "",
      collaborator_name: "",
      collaborator_email: "",
    },
  ]);

  useEffect(() => {
    if (entryType === "1") {
      setTitle("New Project ");
    } else if (entryType === "2") {
      setTitle("Paper to Project ");
    } else {
      setTitle("New Paper ");
    }
  }, [entryType]);

  const navigate = useNavigate();
  useEffect(() => {
    const getAffiliations = async () => {
      try {
        const result = await fetchApi(apiEndpoints.getAffiliations);

        if (result.msg === "success") {
          setAffiliation(result.data);
        } else {
          toast.error(result.response);
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    };
    const getCollaboratorTypes = async () => {
      try {
        const result = await fetchApi(apiEndpoints.getCollaboratorTypes);
        if (result.msg === "success") {
          setCollaboratorType(result.data);
        } else {
          toast.error(result.response);
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    };

    const getMyProjects = async () => {
      try {
        const options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        };
        const result = await fetchApi(apiEndpoints.getUserProjects, options);
        if (result.msg === "error") {
          toast.error(result.response);
        } else {
          setMyProjects(result.submissions);
        }
      } catch (error) {
        localStorage.clear();
        Logout();
        navigate("/login");
      }
    };

    getMyProjects();
    getAffiliations();
    getCollaboratorTypes();
  }, []);

  let first_name = user?.first_name;
  let last_name = user?.last_name;

  const validationSchema = Yup.object({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    affiliation: Yup.string().required("Affiliation is required"),
    institution_id: Yup.string().required("Institution Id is required"),
    orcid: Yup.string().matches(
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/,
      "Please enter a valid URL"
    ),
    project_detail_link: Yup.string()
      .required("Project link is required")
      .matches(
        /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/,
        "Please enter a valid project link"
      ),
    project_title: Yup.string().required("Project/Paper title is required"),
    link: Yup.string().matches(
      /^(https?:\/\/)?(www\.)?(youtube|youtu|youtube-nocookie)\.(com|be)\/(watch\?v=[\w-]+|embed\/[\w-]+|v\/[\w-]+|e\/[\w-]+|.+\/videoseries)/,
      "Please enter a valid YouTube URL"
    ),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "" || first_name,
      last_name: "" || last_name,
      affiliation: "",
      entryType: "",
      project_id: "",
      orcid: "",
      project_title: "",
      doi: "",
      link: "",
      project_detail_link: "",
      institution_id: "",
      video_abstract: "",
      visual_abstract: "",
      description: "",
      collaborations: [
        {
          collaboratortype: "",
          collaboratoraffiliation: "",
          collaborator_name: "",
          collaborator_email: "",
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setFormBusy(true);
        const formData = new FormData();

        if (values.first_name) formData.append("first_name", values.first_name);
        if (values.last_name) formData.append("last_name", values.last_name);
        if (entryType) formData.append("entrytype", entryType);
        if (values.project_id) formData.append("project_id", values.project_id);
        if (values.project_title)
          formData.append("title", values.project_title);
        if (values.affiliation)
          formData.append("affiliation_id", values.affiliation);
        if (values.collaboration) {
          formData.append("collaboration_id", values.collaboration);
        }

        formData.append("collaborations", JSON.stringify(collaborations));
        if (values.orcid) formData.append("orc_id", values.orcid);
        if (values.doi) formData.append("doi", values.doi);
        if (values.link)
          formData.append("post_youtube_link", convertToEmbedUrl(values.link));
        if (values.project_detail_link)
          formData.append("project_detail_link", values.project_detail_link);

        if (institution_id) formData.append("institution_id", institution_id);
        if (video_abstract) formData.append("video_abstract", video_abstract);
        if (visual_abstract)
          formData.append("visual_abstract", visual_abstract);
        if (values.description)
          formData.append("description", values.description);

        const options = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          maxContentLength: 200 * 1024 * 1024,
          maxBodyLength: 200 * 1024 * 1024,
          body: formData,
        };

        const result = await fetchApi(apiEndpoints.createProjects, options);

        if (result.msg === "success") {
          toast.success(result.response);
          formik.resetForm();
          setInstitution_id("");
          setVideo_abstract("");
          setVisual_abstract("");
          setEntryType("");
          setTitle("");
          setCollaborations([]);
        } else if (result.response?.includes("Token expired.")) {
          toast.error(result.response);
          localStorage.clear();
          Logout();
          navigate("/login");
        } else if (result.errors) {
          const errorList = Object.entries(result.errors).map(
            ([field, messages]) => ` ${messages.join(", ")}`
          );
          toast.error(
            <ul>
              {errorList.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>,
            { autoClose: true }
          );
        } else if (result.msg === "error") {
          toast.error(result.response);
        }
      } catch (error) {
        toast.error("Something went wrong");
      } finally {
        setFormBusy(false);
      }
    },
  });

  const handleAddCollaboration = () => {
    setCollaborations([
      ...collaborations,
      {
        collaboratortype: "",
        collaboratoraffiliation: "",
        collaborator_name: "",
        collaborator_email: "",
      },
    ]);
  };
  const handleRemoveCollaboration = () => {
    const updatedCollaborations = [...collaborations];
    updatedCollaborations.pop();
    setCollaborations(updatedCollaborations);
  };
  const handleCollaborationChange = (index, field, value) => {
    const updatedCollaborations = [...collaborations];
    updatedCollaborations[index][field] = value;
    setCollaborations(updatedCollaborations);
    console.log(collaborations);
  };

  return (
    <div className="create-project">
      <Helmet>
        <title>Create New Project - CiteEcho</title>
      </Helmet>
      <section
        id="blog-page"
        className="  inner-page-hero blog-page-section division"
      >
        <div className="container mt-2">
          <div className="row justify-content-center ">
            <div className="col-12 col-lg-10 col-xl-8 wow animate__animated animate__fadeInDown ">
              <div className="card py-4 px-1 px-lg-4">
                <h1 className="text-center h1-title ">
                  Submit Your Video/Visual Abstract
                </h1>
                <br />
                <div className=" row my-4 ">
                  <div className="col-12 col-md-4 text-center d-flex align-items-center justify-content-center ">
                    <label className="label" htmlFor="new-project">
                      New Project
                    </label>
                    <input
                      value="1"
                      onChange={(e) => setEntryType(e.target.value)}
                      type="radio"
                      id="new-project"
                      name="entrytype"
                      className="mx-2"
                      checked={entryType === "1"}
                    />
                  </div>
                  <div className="col-12 col-md-4 text-center d-flex align-items-center justify-content-center">
                    <label className="label" htmlFor="add-paper">
                      Add Paper to Project
                    </label>
                    <input
                      value="2"
                      onChange={(e) => setEntryType(e.target.value)}
                      type="radio"
                      id="add-paper"
                      name="entrytype"
                      className="mx-2"
                      checked={entryType === "2"}
                    />
                  </div>
                  <div className="col-12 col-md-4 text-center d-flex align-items-center justify-content-center">
                    <label className="label" htmlFor="new-paper">
                      New Paper
                    </label>
                    <input
                      value="3"
                      onChange={(e) => setEntryType(e.target.value)}
                      type="radio"
                      id="new-paper"
                      name="entrytype"
                      className="mx-2"
                      checked={entryType === "3"}
                    />
                  </div>
                </div>

                <div className=" Fcontainer register-page">
                  <div className="text-center">
                    <p className="text-center">
                      Please fill out the following information for validation
                      and submission.
                    </p>
                  </div>
                  <form
                    className="register-page-form"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label htmlFor="first_name">
                            First Name{" "}
                            <strong className="text-danger required_label">
                              *
                            </strong>{" "}
                          </label>
                          <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            placeholder="John"
                            required=""
                            className="form-control"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.first_name &&
                          formik.errors.first_name ? (
                            <div className="error-message">
                              {formik.errors.first_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md 6">
                        <div className="form-wrap mb-2">
                          <label htmlFor="last_name">
                            Last Name{" "}
                            <strong className="text-danger required_label">
                              *
                            </strong>{" "}
                          </label>
                          <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            placeholder="Doe"
                            required=""
                            className="form-control"
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.last_name &&
                          formik.errors.last_name ? (
                            <div className="error-message">
                              {formik.errors.last_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-12">
                        <div className="form-wrap mb-2">
                          <label htmlFor="affiliation">
                            Affiliation{" "}
                            <strong className="text-danger required_label">
                              *
                            </strong>{" "}
                          </label>
                          <select
                            type="text"
                            id="affiliation"
                            name="affiliation"
                            placeholder="Your Affiliation"
                            required=""
                            className="form-control select"
                            value={formik.values.affiliation}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option value="">Select Affiliation</option>
                            {affiliation.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.title}
                              </option>
                            ))}
                          </select>
                          {formik.touched.affiliation &&
                          formik.errors.affiliation ? (
                            <div className="error-message">
                              {formik.errors.affiliation}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="row">
                          <div className="col-5 col-sm-3 col-md-3 col-lg-2 me-0 me-md-2">
                            <label>Collaborators</label>
                          </div>
                          <div className="col-5 col-sm-3 col-md-4 col-lg-2 me-0 me-md-2">
                            <FaPlusCircle
                              className="me-2"
                              color="green"
                              size={25}
                              onClick={handleAddCollaboration}
                            />
                            {/* Render collaborators list */}
                            {collaborations.length > 1 && (
                              <span>
                                <FaTimesCircle
                                  color="red"
                                  size={25}
                                  onClick={handleRemoveCollaboration}
                                />
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      {collaborations.map((collaboration_item, index) => (
                        <div key={index} className="collaboration-entry">
                          <div className="row align-items-center">
                            {/* Collaboration Type Select */}
                            <div className="col-12 col-md-6 mb-2">
                              <div className="form-wrap">
                                <label>
                                  Collaborator Type{" "}
                                  <strong className="text-danger required_label">
                                    *
                                  </strong>{" "}
                                </label>
                                <select
                                  id={`collaborations[${index}].collaboratortype`}
                                  name={`collaborations[${index}].collaboratortype`}
                                  value={collaboration_item.collaboratortype}
                                  onChange={(e) =>
                                    handleCollaborationChange(
                                      index,
                                      "collaboratortype",
                                      e.target.value
                                    )
                                  }
                                  className="form-control"
                                >
                                  <option value="">
                                    Select Collaborator Type
                                  </option>
                                  {collaboratortype.map((item) => (
                                    <option key={item.id} value={item.id}>
                                      {item.title}
                                    </option>
                                  ))}
                                </select>
                                {formik.touched.collaborations?.[index]
                                  ?.collaboratortype &&
                                  formik.errors.collaborations?.[index]
                                    ?.collaboratortype && (
                                    <div className="error-message">
                                      {
                                        formik.errors.collaborations[index]
                                          .collaboratortype
                                      }
                                    </div>
                                  )}
                              </div>
                            </div>

                            {/* Collaborator Affiliation Select */}
                            <div className="col-12 col-md-6 mb-2">
                              <div className="form-wrap">
                                <label>
                                  Collaborator Affiliation{" "}
                                  <strong className="text-danger required_label">
                                    *
                                  </strong>
                                </label>
                                <select
                                  id={`collaborations[${index}].collaboratoraffiliation`}
                                  name={`collaborations[${index}].collaboratoraffiliation`}
                                  value={
                                    collaboration_item.collaboratoraffiliation
                                  }
                                  onChange={(e) =>
                                    handleCollaborationChange(
                                      index,
                                      "collaboratoraffiliation",
                                      e.target.value
                                    )
                                  }
                                  className="form-control select"
                                >
                                  <option value="">
                                    Select Collaborator Affiliation
                                  </option>
                                  {affiliation.map((item) => (
                                    <option key={item.id} value={item.id}>
                                      {item.title}
                                    </option>
                                  ))}
                                </select>
                                {formik.touched.collaborations?.[index]
                                  ?.collaboratoraffiliation &&
                                  formik.errors.collaborations?.[index]
                                    ?.collaboratoraffiliation && (
                                    <div className="error-message">
                                      {
                                        formik.errors.collaborations[index]
                                          .collaboratoraffiliation
                                      }
                                    </div>
                                  )}
                              </div>
                            </div>

                            {/* Collaborator Name Input */}
                            <div className="col-12 col-md-6 mb-2">
                              <div className="form-wrap">
                                <label>
                                  Collaborator Name{" "}
                                  <strong className="text-danger required_label">
                                    *
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  id={`collaborations[${index}].collaborator_name`}
                                  name={`collaborations[${index}].collaborator_name`}
                                  value={collaboration_item.collaborator_name}
                                  onChange={(e) =>
                                    handleCollaborationChange(
                                      index,
                                      "collaborator_name",
                                      e.target.value
                                    )
                                  }
                                  className="form-control"
                                  placeholder="Enter Collaborator Name "
                                />
                                {formik.touched.collaborations?.[index]
                                  ?.collaborator_name &&
                                  formik.errors.collaborations?.[index]
                                    ?.collaborator_name && (
                                    <div className="error-message">
                                      {
                                        formik.errors.collaborations[index]
                                          .collaborator_name
                                      }
                                    </div>
                                  )}
                              </div>
                            </div>

                            {/* Collaborator Email Input */}
                            <div className="col-12 col-md-6 mb-2">
                              <div className="form-wrap">
                                <label>
                                  Collaborator Email <strong />{" "}
                                </label>
                                <input
                                  type="email"
                                  id={`collaborations[${index}].collaborator_email`}
                                  name={`collaborations[${index}].collaborator_email`}
                                  value={collaboration_item.collaborator_email}
                                  onChange={(e) =>
                                    handleCollaborationChange(
                                      index,
                                      "collaborator_email",
                                      e.target.value
                                    )
                                  }
                                  className="form-control"
                                  placeholder="Enter Collaborator Email"
                                />
                                {formik.touched.collaborations?.[index]
                                  ?.collaborator_email &&
                                  formik.errors.collaborations?.[index]
                                    ?.collaborator_email && (
                                    <div className="error-message">
                                      {
                                        formik.errors.collaborations[index]
                                          .collaborator_email
                                      }
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label htmlFor="orcid">
                            ORCID <strong />
                          </label>
                          <input
                            type="text"
                            id="orcid"
                            name="orcid"
                            placeholder="Enter your ORCID"
                            required=""
                            className="form-control"
                            value={formik.values.orcid}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.orcid && formik.errors.orcid ? (
                            <div className="error-message">
                              {formik.errors.orcid}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label htmlFor="project_title">
                            {title}
                            {" Title "}
                            <strong className="text-danger required_label">
                              *
                            </strong>{" "}
                          </label>
                          <input
                            type="text"
                            id="project_title"
                            name="project_title"
                            placeholder={"Enter the title of your " + title}
                            required=""
                            className="form-control"
                            value={formik.values.project_title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.project_title &&
                          formik.errors.project_title ? (
                            <div className="error-message">
                              {formik.errors.project_title}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {entryType === "2" && (
                        <div className="col-12 col-md-6">
                          <div className="form-wrap mb-2">
                            <label htmlFor="project_id">
                              Projects{" "}
                              <strong className="text-danger required_label">
                                *
                              </strong>
                            </label>
                            <select
                              type="text"
                              id="project_id"
                              name="project_id"
                              placeholder="Select Project to paper"
                              required=""
                              className="form-control select"
                              value={formik.values.project_id}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option value="">Select Project</option>
                              {myprojects.map((item) => (
                                <option
                                  key={item.submission_id}
                                  value={item.submission_id}
                                >
                                  {item.submission_title}
                                </option>
                              ))}
                            </select>
                            {formik.touched.project_id &&
                            formik.errors.project_id ? (
                              <div className="error-message">
                                {formik.errors.project_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label htmlFor="doi">
                            DOI <strong />
                          </label>
                          <input
                            type="text"
                            id="doi"
                            name="doi"
                            placeholder="Enter the DOI of your project/paper"
                            required=""
                            className="form-control"
                            value={formik.values.doi}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.doi && formik.errors.doi ? (
                            <div className="error-message">
                              {formik.errors.doi}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label htmlFor="project_detail_link">
                            Project Link{" "}
                            <strong className="text-danger required_label">
                              *
                            </strong>
                          </label>
                          <input
                            type="text"
                            id="project_detail_link"
                            name="project_detail_link"
                            placeholder="Enter the project link"
                            className="form-control"
                            value={formik.values.project_detail_link}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.project_detail_link &&
                          formik.errors.project_detail_link ? (
                            <div className="error-message">
                              {formik.errors.project_detail_link}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label htmlFor="link">
                            Post youtube link <strong />
                          </label>
                          <input
                            type="text"
                            id="link"
                            name="link"
                            placeholder="Enter the post youtube link "
                            required=""
                            className="form-control"
                            value={formik.values.link}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.link && formik.errors.link ? (
                            <div className="error-message">
                              {formik.errors.link}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label>
                            Upload your institution ID{" "}
                            <strong className="text-danger required_label">
                              *
                            </strong>
                          </label>
                          <input
                            type="file"
                            id="institution_id"
                            name="institution_id"
                            accept="image/*,application/pdf"
                            required=""
                            className="form-control-md"
                            value={formik.values.institution_id}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setInstitution_id(e.target.files[0]);
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.institution_id &&
                          formik.errors.institution_id ? (
                            <div className="error-message">
                              {formik.errors.institution_id}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label>
                            Upload your video abstract <strong />
                          </label>
                          <input
                            type="file"
                            id="video_abstract"
                            name="video_abstract"
                            accept="video/*"
                            required=""
                            className="form-control-md"
                            value={formik.values.video_abstract}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setVideo_abstract(e.target.files[0]);
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.video_abstract &&
                          formik.errors.video_abstract ? (
                            <div className="error-message">
                              {formik.errors.video_abstract}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label>
                            Upload your visual abstract <strong />
                          </label>
                          <input
                            type="file"
                            id="visual_abstract"
                            name="visual_abstract"
                            accept="image/*"
                            required=""
                            className="form-control-md"
                            value={formik.values.visual_abstract}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setVisual_abstract(e.target.files[0]);
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.video_abstract &&
                          formik.errors.video_abstract ? (
                            <div className="error-message">
                              {formik.errors.video_abstract}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-12">
                        <div className="form-wrap mb-2">
                          <label>
                            Description <strong />
                          </label>
                          <textarea
                            id="description"
                            rows={5}
                            name="description"
                            className="form-control"
                            style={{ minHeight: 100 }}
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.description &&
                          formik.errors.description ? (
                            <div className="error-message">
                              {formik.errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <input
                        value="agree"
                        onChange={(e) => setAgree(e.target.value)}
                        type="radio"
                        id="agrement"
                        name="fav_language"
                        className="me-2"
                      />
                      <label htmlFor="agrement">
                        *By using our service, you agree to our
                        <Link
                          to={"/terms-and-conditions"}
                          target="_blank"
                          className="ms-1"
                          style={{ color: "#f45d08" }}
                        >
                          Terms And Conditions
                        </Link>
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <button
                          type="submit"
                          className="btn r-06 btn--theme hover--black submit w-100"
                          disabled={
                            agree.length === 0 || formBusy ? true : false
                          }
                        >
                          {formBusy ? "Please wait..." : "Submit"}
                        </button>
                      </div>
                      <div className="col-12 col-md-6 mt-3 mt-md-0">
                        <button
                          type="button"
                          className="btn   cancel w-100"
                          style={{ backgroundColor: "#aca8a8" }}
                          onClick={() => navigate("/profile")}
                        >
                          {"Cancel"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateProject;
