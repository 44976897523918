import React from "react";
import "./about.css";
import { Helmet } from "react-helmet-async";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About - CiteEcho</title>
      </Helmet>
      <section
        className="main-banner-in"
        style={{ backgroundImage: "url(/assets/images/banner-3.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="banner-in-title wow fadeInUp  animated"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInUp",
                }}
              >
                <h1 className="h1-title">About</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-100 bc-04 ws-wrapper content-section">
        <div className="container">
          <div className="bc-04-wrapper r-24">
            <div className="row d-flex align-items-center py-3">
              <div className="col-md-12 col-lg-12 order-last order-md-2"></div>
              <div className="col-md-12 col-lg-12 order-first order-md-2">
                <div className="txt-block left-column wow animate__animated animate__fadeInRight">
                  <h2 className="h2-md">
                    Amplify your Research with Impactful Videos and Infographics
                  </h2>
                  <p>
                    Elevate your research visibility and impact by creating
                    dynamic video abstracts and highlights of your scholarly
                    papers and conference presentations. Bring your research to
                    life with CiteEcho! in minutes and watch as your citations
                    grow. Don't just put it out there—make it count!
                  </p>
                </div>
                <div className=" video-preview ">
                  <img
                    class="img-fluid r-24 py-2"
                    src="/assets/images/trademark.jpg"
                    alt="content-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4 about-2 about-section division">
        <div className="container">
          <div className="row">
            <div className="col"></div>
          </div>

          <div className="row mt-5">
            <div className="col-md-6 ">
              <div className="about-2-title ">
                <h2 className="h4-lg">Discover</h2>
              </div>
              <div id="a2-1" className="txt-block">
                <p>
                  Discover provides a platform for researchers, industry, and
                  government to investigate projects that link media content
                  with research papers and projects. Using Discover, you can
                  produce reports with visual elements to effectively present
                  information to an audience. With the Google Scholar extension,
                  you can view media linked to research papers during your
                  searches on the platform. Download the extension now to
                  enhance your search experience and make it more engaging.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <h2 className="h2-lg">Download the Google Scholar Extension</h2>
              <img
                className="mt-4"
                src="/assets/images/extension.png"
                alt="discover picture"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5 pb-2 gr--whitesmoke pricing-1 pb-20 inner-page-hero pricing-section division">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8">
              <div className="section-title mb-60 text-center">
                <h2 className="h2-title">Explore CiteEcho Plans</h2>
              </div>
            </div>
          </div>
          <div className="pricing-1-wrapper pricing_cards_custom">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 justify-content-md-center">
              <div className="col mb-3">
                <div
                  id="pt-1-1"
                  className="p-table pricing-1-table bg--white block--shadow r-12 wow animate__animated animate__fadeInUp h-100"
                >
                  <div className="pricing-table-header">
                    <h5 className="h5-md">Individuals</h5>
                    <div className="price">
                      <span className="">
                        Basic: <span className="offer-price px-2">$ 10</span> Free
                      </span>
                      <h6 className="h6-md advanced">Advanced: <span className="price"> $30 </span> </h6>
                    </div>
                  </div>

                  <ul className="pricing-features ico-10 ico--theme-2 mt-25">
                    <li>
                      <p>
                        <span className="flaticon-check" /> Visual abstract
                        creation toolbox
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="flaticon-check" /> Manual upload to
                        ORCID
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="flaticon-check" /> Add to CiteEcho Feed
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="flaticon-check" /> Video highlight
                        creation toolbox
                      </p>
                    </li>
                    <li>
                      <p className="advanced">
                        <span className="flaticon-check" /> Automated upload to
                        ORCID
                      </p>
                    </li>
                    <li>
                      <p className="advanced">
                        <span className="flaticon-check" /> Personal media
                        profile
                      </p>
                    </li>
                    <li>
                      <p className="advanced">
                        <span className="flaticon-check" /> Track interactions,
                        grant recommendations, collaboration recommendations,
                        research communication index, insights board
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col mb-3">
                <div
                  id="pt-1-2"
                  className="p-table pricing-1-table bg--white block--shadow r-12 wow animate__animated animate__fadeInUp h-100 "
                >
                  <div className="pricing-table-header">
                    <h5 className="h5-md">Industry</h5>
                    <div className="price">
                      <span className="">
                        Basic: <span className="offer-price px-2">$ 500</span>{" "}
                        $350
                      </span>
                      <h6 className="h6-md advanced">Advanced: <span className="price">$1500</span></h6>
                    </div>
                  </div>
                  <ul className="pricing-features ico-10 ico--theme-2 mt-25">
                    <li>
                      <p>
                        <span className="flaticon-check" /> Project Ad creation
                        toolbox
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="flaticon-check" /> Project tracking
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="flaticon-check" /> Add to CiteEcho Feed
                      </p>
                    </li>
                    <li>
                      <p className="advanced">
                        <span className=" flaticon-check" /> Partnership
                        recommendations
                      </p>
                    </li>
                    <li>
                      <p className="advanced">
                        <span className="flaticon-check" /> Data insight
                      </p>
                    </li>
                    <li>
                      <p className="advanced">
                        <span className="flaticon-check" /> Extract and use data
                        from papers for briefings, track projects
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col mb-3">
                <div
                  id="pt-1-2"
                  className="p-table pricing-1-table bg--white block--shadow r-12 wow animate__animated animate__fadeInUp h-100"
                >
                  <div className="pricing-table-header">
                    <h5 className="h5-md">Academia</h5>
                    <a
                      href="#"
                      className="pt-btn btn r-36 btn--theme hover--tra-black"
                    >
                      Check your institution access
                    </a>
                    <a
                      href="#"
                      className="pt-btn btn r-36 btn--theme hover--tra-black"
                    >
                      Recommend for your institution
                    </a>
                  </div>

                  <ul className="pricing-features ico-10 ico--theme-2 mt-25">
                    <li>
                      <p>
                        <span className="flaticon-check" /> Pro-Package: Offered
                        to grad students and professors within departments at a
                        negotiated access price
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="flaticon-check" /> Lab-Package: Offered
                        to lab teams
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" pt-10 pt-md-100 bc-04 ws-wrapper  wow animate__animated animate__fadeInUp ">
        <div className="container my-5">
          <h2 className="section-title">Our Contact Information</h2>
          <div className="row gy-4">
            <div className="col-md-4">
              <div className="contact-info-card  wow animate__animated animate__fadeInUp">
                <svg
                  fill="#f45d08"
                  width="800px"
                  height="800px"
                  viewBox="0 -12 66.417 66.417"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Path_25"
                    data-name="Path 25"
                    d="M330.538,933.924h0v-.017c0-.029-.007-.057-.009-.086a1.353,1.353,0,0,0-.02-.2,1.279,1.279,0,0,0-.04-.138,1.467,1.467,0,0,0-.044-.143,1.555,1.555,0,0,0-.09-.172c-.015-.027-.026-.057-.044-.084l0-.006a1.815,1.815,0,0,0-.386-.394l-.023-.02a1.569,1.569,0,0,0-.166-.089c-.031-.016-.06-.036-.092-.05a1.721,1.721,0,0,0-.189-.059,1.422,1.422,0,0,0-.16-.034,1.529,1.529,0,0,0-.223-.023H265.612a1.529,1.529,0,0,0-.224.023,1.408,1.408,0,0,0-.159.034,1.811,1.811,0,0,0-.19.059c-.032.014-.06.034-.091.05a1.473,1.473,0,0,0-.166.089l-.023.02a1.43,1.43,0,0,0-.255.232,1.461,1.461,0,0,0-.132.162l-.005.006c-.018.027-.028.057-.044.084a1.394,1.394,0,0,0-.089.172,1.474,1.474,0,0,0-.045.143c-.013.046-.03.09-.039.138a1.5,1.5,0,0,0-.021.2c0,.029-.008.057-.008.086v.017h0v39.414a1.559,1.559,0,0,0,.02.192c0,.034.005.07.012.1.011.05.03.1.046.148a1.232,1.232,0,0,0,.044.134,1.026,1.026,0,0,0,.053.1,1.518,1.518,0,0,0,.092.163l.008.014c.012.016.027.027.039.043a1.422,1.422,0,0,0,.178.194c.028.026.058.049.088.073a1.49,1.49,0,0,0,.183.122c.033.019.065.038.1.054a1.546,1.546,0,0,0,.225.082c.028.008.055.019.083.026a1.542,1.542,0,0,0,.329.038h63.417a1.533,1.533,0,0,0,.328-.038c.029-.007.056-.018.084-.026a1.546,1.546,0,0,0,.225-.082c.034-.016.066-.036.1-.054a1.593,1.593,0,0,0,.182-.121c.031-.024.061-.048.09-.075a1.4,1.4,0,0,0,.176-.193c.012-.016.028-.026.039-.043l.008-.014a1.374,1.374,0,0,0,.093-.163c.017-.032.038-.064.053-.1s.029-.089.044-.134.035-.1.046-.148c.007-.033.007-.069.012-.1a1.388,1.388,0,0,0,.019-.192l0-.017Zm-63.417,2.763,24.66,16.214-24.66,17.515Zm30.209,16.272-26.7-17.553h53.4Zm-2.859,1.71,2.034,1.338a1.5,1.5,0,0,0,1.649,0l2.034-1.337,24.148,17.151H270.323Zm8.407-1.768,24.66-16.214v33.729Z"
                    transform="translate(-264.121 -932.406)"
                  />
                </svg>

                <div className="content ps-3">
                  <h5>Email</h5>
                  <a href="mailto:admin@citeecho.com">admin@citeecho.com</a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-info-card  wow animate__animated animate__fadeInUp">
                <svg
                  width="800px"
                  height="800px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C14.1588 20.0658 10.9183 19.5829 7.6677 16.3323C4.41713 13.0817 3.93421 9.84122 4.00655 7.93309C4.04952 6.7996 4.7008 5.77423 5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617Z"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>

                <div className="content ps-3">
                  <h5>Contact Number</h5>
                  <a href="tel:(226) 898-5289">(226) 898-5289</a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-info-card  wow animate__animated animate__fadeInUp">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke="#CCCCCC"
                    strokeWidth="0.288"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 12.7215 17.8726 13.4133 17.6392 14.054C17.5551 14.285 17.4075 14.4861 17.2268 14.6527L17.1463 14.727C16.591 15.2392 15.7573 15.3049 15.1288 14.8858C14.6735 14.5823 14.4 14.0713 14.4 13.5241V12M14.4 12C14.4 13.3255 13.3255 14.4 12 14.4C10.6745 14.4 9.6 13.3255 9.6 12C9.6 10.6745 10.6745 9.6 12 9.6C13.3255 9.6 14.4 10.6745 14.4 12Z"
                      strokeWidth="1.128"
                      strokeLinecap="round"
                    />
                    <path
                      d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                      strokeWidth="1.128"
                    />
                  </g>
                </svg>

                <div className="content ps-3">
                  <h5>Social Links</h5>
                  <a href="#">Facebook</a> &nbsp;&nbsp; | &nbsp;&nbsp;
                  <a href="#">Instagram</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
