import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Auth.css";
import { useApi } from "../../context/ApiContext";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/AuthContext";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Helmet } from "react-helmet-async";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { apiEndpoints, fetchApi } = useApi();
  const [formBusy, setFormBusy] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { Login } = useContext(AuthContext);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters long")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setFormBusy(true);
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: values.email,
            password: values.password,
          }),
        };
        const result = await fetchApi(apiEndpoints.userLogin, options);

        if (result.msg === "error") {
          toast.error(result.response);
        } else if (result.errors) {
          Object.entries(result.errors).forEach(([field, messages]) => {
            toast.error(`${field}: ${messages.join(", ")}`);
          });
        } else {
          Login(result.token, result.user);
          navigate("/profile");
        }
      } catch (error) {
        toast.error("An unexpected error occurred. Please try again.");
      } finally {
        setFormBusy(false);
      }
    },
  });

  return (
    <div>
      <Helmet>
        <title>Login - CiteEcho</title>
      </Helmet>
      <div id="login" className="register-page division">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-5">
              <div className="register-page-form wow animate__animated animate__fadeInUp">
                <div className="register-form-title">
                  <h1 className="h1-title">Login</h1>

                  <p>Access your account to get started.</p>
                </div>
                <form
                  onSubmit={formik.handleSubmit}
                  className="row sign-in-form"
                >
                  <div className="col-md-12 mb-4">
                    <input
                      className="form-control email"
                      type="email"
                      name="email"
                      placeholder="Enter Your Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="error-message">{formik.errors.email}</div>
                    )}
                  </div>

                  <div className="col-md-12">
                    <div className="wrap-input mb-4">
                      <span
                        className="btn-show-pass ico-20"
                        onClick={togglePassword}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className={` eye-pass ${
                            showPassword
                              ? "flaticon-invisible "
                              : "flaticon-visibility"
                          }`}
                        />
                      </span>
                      <input
                        className="form-control password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Enter Your Password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.password && formik.errors.password && (
                        <div className="error-message">
                          {formik.errors.password}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="reset-password-link">
                      <p className="p-sm">
                        <Link to="/forgot">Forgot your password?</Link>
                      </p>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="btn r-06 btn--theme hover--black submit"
                      disabled={formBusy}
                    >
                      {formBusy ? "Please wait..." : "Get Started"}
                    </button>
                  </div>

                  <div className="col-md-12 text-center">
                    <p className="create-account">
                      Don't have an account yet?{" "}
                      <Link to="/signup" className="color--theme">
                        Register now
                      </Link>
                    </p>
                  </div>
                </form>{" "}
              </div>
            </div>
          </div>{" "}
        </div>{" "}
      </div>
    </div>
  );
};

export default Login;
