import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Navigate } from "react-router-dom";

const AuthProtected = ({ children }) => {
  const { token } = useContext(AuthContext);
  if (token) {
    return <Navigate to={"/profile"} />;
  }
  return children;
};

export default AuthProtected;
