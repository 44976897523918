import React, { useEffect, useState } from "react";
import "./Home.css";
import { Helmet } from "react-helmet-async";
import { useApi } from "../../context/ApiContext";
import { toast } from "react-toastify";

const Home = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [collaborationFilter, setCollaborationFilter] = useState("");
  const [affiliationFilter, setAffiliationFilter] = useState("");
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [affiliation, setAffiliation] = useState([]);
  const [collaborationTypes, setCollaborationTypes] = useState([]);
  const { apiEndpoints, fetchApi } = useApi();

  useEffect(() => {
    const getCollaborations = async () => {
      try {
        const result = await fetchApi(apiEndpoints.getCollaboratorTypes);

        if (result.msg === "success") {
          setCollaborationTypes(result.data);
        } else {
          toast.error(result.response);
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    };

    const getAffiliations = async () => {
      try {
        const result = await fetchApi(apiEndpoints.getAffiliations);

        if (result.msg === "success") {
          setAffiliation(result.data);
        } else {
          toast.error(result.response);
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    };

    getAffiliations();
    getCollaborations();
  }, [
    apiEndpoints.getAffiliations,
    apiEndpoints.getCollaboratorTypes,
    fetchApi,
  ]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await fetchApi(apiEndpoints.getProjects);
        if (data.msg === "success") {
          setProjects(data.submissions);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUsers();
  }, [apiEndpoints, fetchApi]);

  const handleSearchInput = (e) => {
    setSearchTerm(e.target.value.trim());
  };

  const filteredProjects = projects.filter((project) => {
    const matchesSearch =
      !searchTerm ||
      (project.submission_title &&
        project.submission_title
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) ||
      (project.description &&
        project.description.toLowerCase().includes(searchTerm.toLowerCase()));

    const matchesCollaboration =
      !collaborationFilter ||
      (Array.isArray(project.collaborators) &&
        project.collaborators.some(
          (collaborator) =>
            String(collaborator.collaboration_type_id) === collaborationFilter
        ));

    const matchesAffiliation =
      !affiliationFilter ||
      String(project.affiliation_id) === affiliationFilter ||
      (Array.isArray(project.collaborators) &&
        project.collaborators.some(
          (collaborator) =>
            String(collaborator.collaborator_affiliation_id) ===
            affiliationFilter
        ));

    return matchesSearch && matchesCollaboration && matchesAffiliation;
  });

  const getFirst20Words = (text) => {
    if (!text) {
      return "";
    }

    const words = text.split(" ");
    if (words.length > 20) {
      return words.slice(0, 20).join(" ") + "...";
    } else if (words.length > 1) {
      return text;
    } else {
      return "";
    }
  };

  return (
    <>
      <Helmet>
        <title>Projects - CiteEcho</title>
      </Helmet>
      <section
        id="blog-page"
        className=" pb-20 inner-page-hero blog-page-section division"
      >
        <div className="container mt-2">
          <div className="row ">
            <div className="col">
              <div className="posts-category ico-20 animate__animated animate__fadeInUp">
                <h5 className="text-indigo mb-2">Search Projects/Papers</h5>
              </div>
            </div>
          </div>

          <div className="row align-items-center mb-2 animate__animated animate__fadeInUp ">
            <div className="col">
              <div
                className="card border-0"
                style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px" }}
              >
                <div className="card-body">
                  <div className="search-filter-container animate__animated animate__fadeInUp">
                    <div className="filters-container">
                      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                        <div className="col">
                          <input
                            type="text"
                            id="searchBox"
                            onChange={handleSearchInput}
                            placeholder="Search For Projects/Papers"
                            className="form-control my-2"
                          />
                        </div>
                        <div className="col">
                          <select
                            id="collaborationFilter"
                            className="form-select my-2"
                            value={collaborationFilter}
                            onChange={(e) =>
                              setCollaborationFilter(e.target.value)
                            }
                          >
                            <option value="">All Collaboration Types</option>
                            {collaborationTypes.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.title}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col">
                          <select
                            id="affiliationFilter"
                            className="form-select my-2"
                            value={affiliationFilter}
                            onChange={(e) =>
                              setAffiliationFilter(e.target.value)
                            }
                          >
                            <option value="">All Affiliations</option>
                            {affiliation.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-3">
            <div className="col">
              <div className="posts-category ico-20 animate__animated animate__fadeInUp">
                <h3 className="h3-lg">Latest Projects/Papers</h3>
              </div>
            </div>
          </div>

          <div className="posts-wrapper">
            {isLoading ? (
              <div className="row justify-content-center custom_h">
                <div className="col-12 text-center">
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            ) : projects.length === 0 ? (
              <div className="row justify-content-start custom_h">
                <div className="col-12 text-start">
                  <p>No projects/papers found.</p>
                </div>
              </div>
            ) : filteredProjects.length === 0 ? (
              <div className="row justify-content-start custom_h">
                <div className="col-12 text-start">
                  <p>No projects/papers found matching your filters.</p>
                </div>
              </div>
            ) : (
              <div className="row gx-2 row-cols-1 row-cols-md-2 row-cols-lg-3">
                {filteredProjects.map((project) => (
                  <div className="col mb-4" key={project.project_id}>
                    <div className="blog-post wow animate__animated animate__fadeInUp h-100">
                      <div className="blog-post-img r-12">
                        {project.video_abstract ? (
                          <video
                            src={project.video_abstract}
                            className="card_image"
                            controls
                          >
                            Your browser does not support the video tag.
                          </video>
                        ) : project.post_youtube_link ? (
                          <iframe
                            src={project.post_youtube_link}
                            className="card_image"
                            width="100%"
                            title={project.project_title}
                          />
                        ) : project.visual_abstract ? (
                          <img
                            src={project.visual_abstract}
                            alt={project.project_title}
                            width="100%"
                            className="card_image"
                          />
                        ) : (
                          <p>No media available</p>
                        )}
                      </div>

                      <div className="blog-post-txt  pb-3">
                        <h6 className="post_title">
                          <a
                            href={project.project_detail_link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {project.submission_title}
                          </a>
                        </h6>
                        <p className="pb-2">
                          {getFirst20Words(project.description)}
                        </p>

                        <p className="pb-2">
                          <strong>Affiliation:</strong>{" "}
                          {project.affiliation_name}
                        </p>
                        {project?.collaborators?.[0]
                          ?.collaborator_affiliation_id !== null && (
                          <p className="pb-2">
                            <strong>Collaborators:</strong>

                            {project?.collaborators?.map(
                              (collaborator, index) => (
                                <li key={index} className="custom_ul">
                                  <strong className="custom_text">
                                    {collaborator.collaborator_name}{" "}
                                  </strong>
                                  <span>
                                    (
                                    <span className="dark_color">
                                      {collaborator.collaboration_type}
                                    </span>
                                    ,{" "}
                                    <span className="darko_color">
                                      {collaborator.collaborator_affiliation}
                                    </span>
                                    )
                                  </span>
                                </li>
                              )
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
