import React, { useState, useContext } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useApi } from '../../context/ApiContext'
import { toast } from 'react-toastify'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../../context/AuthContext';
import { Helmet } from "react-helmet-async";
const ChangePassword = () => {
    const navigate = useNavigate()
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const [formBusy, setFormBusy] = useState(false)
    const { apiEndpoints, fetchApi } = useApi();
    const { token, Logout } = useContext(AuthContext)
    const togglePassword = () => {
        setShowPassword(!showPassword);
    };
    const toggleCPassword = () => {
        setShowCPassword(!showCPassword);
    };
    const toggleOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };
    const validationSchema = Yup.object({
        old_password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Old password is required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('New password is required'),
        cPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm password is required'),
    });
    const formik = useFormik({
        initialValues: {
            old_password: "",
            password: '',
            cPassword: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                setFormBusy(true);
                // API call for password reset
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `bearer ${token}`
                    },
                    body: JSON.stringify({
                        old_password: values.old_password,
                        new_password: values.password,
                        c_new_password: values.cPassword
                    }),
                };
                const result = await fetchApi(apiEndpoints.updatePassword, options);
                if (result.msg === 'error') {
                    toast.error(result.response);
                } else {
                    toast.success(result.response);
                    formik.resetForm();
                }
            } catch (error) {
                console.log(error)
                toast.error('An error occurred. Please try again later.');
                localStorage.clear();
                Logout();
                navigate("/login");
            } finally {
                setFormBusy(false);
            }
        },
    });
    return (
        <div>
            <Helmet>
                <title>Change Password - CiteEcho</title>
            </Helmet>
            <div id="reset-password" className="register-page division">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-7 col-lg-5">
                            <div className="reset-page-wrapper">

                                <div className="reset-form-title">

                                    <h1 className="h1-title" >Change Password</h1>

                                    <p className="p-sm">
                                        Update your password for CiteEcho account.
                                    </p>
                                </div>

                                <form onSubmit={formik.handleSubmit} className="row reset-password-form">
                                    <div className="col-md-12">
                                        <div className="wrap-input mb-4">
                                            <span
                                                className="btn-show-pass ico-20"
                                                onClick={toggleOldPassword}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <span
                                                    className={` eye-pass ${showOldPassword ? "flaticon-invisible " : "flaticon-visibility"}`}
                                                />
                                            </span>
                                            <input
                                                className="form-control password text-start"
                                                type={showOldPassword ? "text" : "password"}
                                                name="old_password"
                                                placeholder="Enter old password"

                                                value={formik.values.old_password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.old_password && formik.errors.old_password && (
                                                <div className="error-message">{formik.errors.old_password}</div>
                                            )}
                                        </div>
                                    </div>


                                    <div className="col-md-12">
                                        <div className="wrap-input mb-4">
                                            <span
                                                className="btn-show-pass ico-20"
                                                onClick={togglePassword}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <span
                                                    className={` eye-pass ${showPassword ? "flaticon-invisible " : "flaticon-visibility"}`}
                                                />
                                            </span>
                                            <input
                                                className="form-control password text-start"
                                                type={showPassword ? "text" : "password"}
                                                name="password"
                                                placeholder="Enter New Password"

                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.password && formik.errors.password && (
                                                <div className="error-message">{formik.errors.password}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="wrap-input mb-4">
                                            <span
                                                className="btn-show-pass ico-20"
                                                onClick={toggleCPassword}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <span
                                                    className={` eye-pass ${showCPassword ? "flaticon-invisible " : "flaticon-visibility"}`}
                                                />
                                            </span>
                                            <input
                                                className="form-control password text-start"
                                                type={showCPassword ? "text" : "password"}
                                                name="cPassword"
                                                placeholder="Re-enter New Password "

                                                value={formik.values.cPassword}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.cPassword && formik.errors.cPassword && (
                                                <div className="error-message">{formik.errors.cPassword}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <button
                                            type="submit"
                                            className="btn btn--theme hover--black submit"
                                            disabled={formBusy}
                                        >
                                            {formBusy ? "Please wait..." : "Change Password"}
                                        </button>
                                    </div>



                                    <div className="col-lg-12 reset-form-msg">
                                        <span className="loading" />
                                    </div>
                                </form>{" "}

                            </div>
                        </div>
                    </div>{" "}

                </div>{" "}

            </div>
        </div>
    )
}

export default ChangePassword