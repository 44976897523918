import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useApi } from "../../context/ApiContext";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./Auth.css";
import { Helmet } from "react-helmet-async";
const Forgot = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { apiEndpoints, fetchApi } = useApi();
  const [formBusy, setFormBusy] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: email,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setFormBusy(true);
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: values.email,
          }),
        };
        const result = await fetchApi(apiEndpoints.userForgot, options);

        if (result.msg === "error") {
          toast.error(result.response);
        } else if (result.errors) {
          toast.error(
            <ul>
              {Object.entries(result.errors).map(([field, messages], index) => (
                <li
                  key={index}
                  style={{ listStyleType: "none", lineHeight: 0.5 }}
                >
                  {messages.join(", ")}
                </li>
              ))}
            </ul>
          );
        } else {
          toast.success(result.response);
          console.log(result);
        }
      } catch (error) {
        toast.error("Something went wrong, please try again.");
      } finally {
        setFormBusy(false);
      }
    },
  });
  return (
    <div>
      <Helmet>
        <title>Forgot Password - CiteEcho</title>
      </Helmet>
      <div id="reset-password" className="register-page division">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-5">
              <div className="reset-page-wrapper wow animate__animated animate__fadeInUp">
                <div className="reset-form-title">
                  <h1 className="h1-title">Forgot</h1>

                  <p className="p-sm">
                    Enter your email address below and we'll send you a link to
                    securely reset your password
                  </p>
                </div>
                <form
                  onSubmit={formik.handleSubmit}
                  className="row reset-password-form"
                >
                  <div className="col-md-12 mb-4 ">
                    <input
                      className="form-control email text-start"
                      type="email"
                      name="email"
                      placeholder="example@example.com"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="error-message">{formik.errors.email}</div>
                    )}
                  </div>

                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="btn btn--theme hover--black submit"
                      disabled={formBusy || !formik.isValid}
                    >
                      {formBusy ? "Please wait..." : "Reset My Password"}
                    </button>
                  </div>

                  <div className="col-md-12">
                    <div className="form-data text-center">
                      <p className="p-sm">
                        <Link to="/login">Back to Login</Link>
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-12 reset-form-msg">
                    <span className="loading" />
                  </div>
                </form>{" "}
              </div>
            </div>
          </div>{" "}
        </div>{" "}
      </div>
    </div>
  );
};

export default Forgot;
