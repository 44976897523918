import React, { createContext, useContext } from "react";

const ApiContext = createContext();

const ApiProvider = ({ children }) => {
  // const baseUrl = "http://127.0.0.1:920/api";
  const baseUrl = "https://www.citeecho.com/portal/api";
  // const baseUrl = "https://explorelogicsit.net/citeecho/portal/api";

  const apiEndpoints = {
    userSignup: `${baseUrl}/signup`,
    userLogin: `${baseUrl}/signin`,
    userForgot: `${baseUrl}/forgot-password`,
    resetPassword: `${baseUrl}/reset-password`,
    userProfile: `${baseUrl}/profile`,
    verifyAccount: `${baseUrl}/verify-account`,
    updatePassword: `${baseUrl}/updatePassword`,
    getAffiliations: `${baseUrl}/get_affiliations`,
    getCollaboratorAffiliations: `${baseUrl}/get_collaborator_affiliations`,
    getCollaboratorTypes: `${baseUrl}/get_collaborator_types`,
    getProjects: `${baseUrl}/get_submissions`,
    getUserProjects: `${baseUrl}/get-user-projects`,
    createProjects: `${baseUrl}/project-submit`,
    editProject: `${baseUrl}/edit_submission`,
    UpdateProject: `${baseUrl}/update_submission`,
    UpdateProjectVisibility: `${baseUrl}/update_visibility`,
    getMyProjects: `${baseUrl}/get-my-submissions`,
    reviewSubmission: `${baseUrl}/review-my-submission`,
  };

  const fetchApi = async (endpoint, options = {}) => {
    try {
      const response = await fetch(endpoint, options);
      return response.json();
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <ApiContext.Provider value={{ apiEndpoints, fetchApi }}>
      {children}
    </ApiContext.Provider>
  );
};

// Custom hook for consuming the API context
export const useApi = () => {
  return useContext(ApiContext);
};

export default ApiProvider;
