import React from "react";
import "./CreateVideo.css";
import { Helmet } from "react-helmet-async";
const CreateVideo = () => {
  return (
    <>
      <Helmet>
        <title>Create - CiteEcho</title>
      </Helmet>
      <section
        className="main-banner-in create-banner"
        style={{
          backgroundImage: "url(/assets/images/banner-22.jpg)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="banner-in-title wow fadeInUp  animated"
                data-wow-delay=".4s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.4s",
                  animationName: "fadeInUp",
                }}
              >
                <h1 className="h1-title">Create</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-100 bc-04 ws-wrapper content-section">
        <div className="container">
          <div className="bc-04-wrapper r-24">
            <div className="row d-flex align-items-center">
              <div className="col-md-12 col-lg-12 order-last order-md-2"></div>
              <div className="col-md-12 col-lg-12 order-first order-md-2">
                <div className="txt-block left-column wow animate__animated animate__fadeInRight">
                  <span className="section-id"></span>
                  <h2 className="h2-md">Creating Videos and infographics</h2>
                  <p>
                    Use the create tool to develop media for your projects and
                    papers.
                  </p>
                  <p className="mb-0">
                    Our AI-powered tools let users extract content from research
                    papers to create engaging visual and video summaries.
                    Communicate the impact of your work within minutes.
                  </p>
                </div>
                <div className=" video-preview ">
                  <img
                    class="img-fluid r-24 "
                    src="/assets/images/video.jpg"
                    alt="content-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-100 bc-04 ws-wrapper ">
        <div className="container">
          <div className=" r-24">
            <div class="row">
              <div class="col mb-3">
                <div class="create_video_card p-table pricing-1-table bg--white block--shadow r-12 wow animate__ animate__fadeInUp animated h-100">
                  <h4 className="mb-2">Submitting Your Research Video</h4>
                  <p>
                    Prepare your video ensuring it meets our submission
                    guidelines, including format, length, and content relevance
                    to academic research.
                  </p>
                  <button class="mb-2 btn btn-sm r-36 btn--theme hover--tra-black">
                    Submit Video
                  </button>
                  <ul>
                    <li>
                      <strong>Upload Your Video:</strong> Navigate to the
                      submission section, and upload your video file. Provide a
                      brief description, select relevant tags, and link it to
                      the corresponding research paper or article.
                    </li>
                    <li>
                      <strong>Review Process:</strong> Once submitted, your
                      video will undergo a review process to ensure it meets
                      academic standards and quality benchmarks.
                    </li>
                    <li>
                      <strong>Publication:</strong> After approval, your video
                      will be published on the CiteEcho platform, making it
                      accessible to a global audience of academics and
                      researchers.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col mb-3">
                <div class="create_video_card p-table pricing-1-table bg--white block--shadow r-12 wow animate__ animate__fadeInUp animated h-100">
                  <h4 className="mb-2">Generating Videos with CiteEcho</h4>
                  <p>
                    Upload your research paper and our AI-powered tool will
                    analyze it for key metrics, findings, images, methodology,
                    and results. It will create a script and develop the layout
                    of your video.
                  </p>
                  <button class="mb-2 btn btn-sm r-36 btn--theme hover--tra-black ">
                    Generate Video
                  </button>
                  <ul>
                    <li>
                      <strong>Customization:</strong> Review the generated video
                      and use our editing tools to make adjustments, add
                      visuals, or incorporate additional information to better
                      convey your research.
                    </li>
                    <li>
                      <strong>Final Review and Submission:</strong> Once you are
                      satisfied with the video, submit it for a quick review to
                      ensure compliance with our content standards.
                    </li>
                    <li>
                      <strong>Publishing:</strong> After the review, your video
                      will be published on CiteEcho, ready to be viewed and
                      shared with the academic community.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateVideo;
