import React, { useState, useEffect, useContext } from "react";
import { useApi } from "../../context/ApiContext";
import "./create-project.css";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { AuthContext } from "../../context/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import { FaPlus, FaPlusCircle, FaTimesCircle } from "react-icons/fa";

function convertToEmbedUrl(url) {
  const patterns = [
    /^https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/,
    /^https:\/\/youtu\.be\/([a-zA-Z0-9_-]{11})/,
    /^https:\/\/www\.youtube\.com\/embed\/([a-zA-Z0-9_-]{11})/,
    /^https:\/\/www\.youtube\.com\/v\/([a-zA-Z0-9_-]{11})/,
  ];
  for (const pattern of patterns) {
    const match = pattern.exec(url);
    if (match) {
      return `https://www.youtube.com/embed/${match[1]}`;
    }
  }
  return null;
}

const EditProject = () => {
  const { submissionId } = useParams();
  const [projectData, setProjectData] = useState(null);
  const [affiliation, setAffiliation] = useState([]);
  const [myprojects, setMyProjects] = useState([]);
  const [formBusy, setFormBusy] = useState(false);
  const [agree, setAgree] = useState("");

  const [institution_id, setInstitution_id] = useState();
  const [video_abstract, setVideo_abstract] = useState();
  const [visual_abstract, setVisual_abstract] = useState();

  const [old_institution_id, setOldInstitution_id] = useState();
  const [old_video_abstract, setOldVideo_abstract] = useState();
  const [old_visual_abstract, setOldVisual_abstract] = useState();

  const { apiEndpoints, fetchApi } = useApi();
  const { token, Logout } = useContext(AuthContext);

  const [entryType, setEntryType] = useState();
  const [title, setTitle] = useState();
  const [collaboratortype, setCollaboratorType] = useState([]);
  const [collaborations, setCollaborations] = useState([]);

  useEffect(() => {
    if (entryType === "1" || entryType === 1) {
      setTitle("New Project Title");
    } else if (entryType === "2" || entryType === 2) {
      setTitle("Paper to Project Title");
    } else {
      setTitle("New Paper Title");
    }
  }, [entryType]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjectDetails = async (id) => {
      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ project_id: id }),
        };
        const result = await fetchApi(apiEndpoints.editProject, options);
        console.log(result);
        if (result.msg === "success") {
          setProjectData(result.submission);
          setCollaborations(result.submission.collaborators);
        } else {
          toast.error(result.response);
          if (result.response.includes("Token expired.")) {
            localStorage.clear();
            Logout();
            navigate("/login");
          }
        }
      } catch (error) {
        toast.error("Something went wrong while fetching project details.");
        localStorage.clear();
        Logout();
        navigate("/login");
      }
    };

    const getAffiliations = async () => {
      try {
        const result = await fetchApi(apiEndpoints.getAffiliations);
        if (result.msg === "success") {
          setAffiliation(result.data);
        } else {
          toast.error(result.response);
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    };

    const getCollaboratorTypes = async () => {
      try {
        const result = await fetchApi(apiEndpoints.getCollaboratorTypes);
        if (result.msg === "success") {
          setCollaboratorType(result.data);
        } else {
          toast.error(result.response);
        }
      } catch (error) {
        toast.error("Something went wrong");
      }
    };

    const getMyProjects = async () => {
      try {
        const options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${token}`,
          },
        };
        const result = await fetchApi(apiEndpoints.getUserProjects, options);
        if (result.msg === "error") {
          toast.error(result.response);
        } else {
          setMyProjects(result.submissions);
        }
      } catch (error) {}
    };

    getMyProjects();
    fetchProjectDetails(submissionId);
    getAffiliations();
    getCollaboratorTypes();
  }, [submissionId]);

  const validationSchema = Yup.object({
    project_title: Yup.string().required("Project title is required"),
    affiliation: Yup.string().required("Affiliation is required"),
    orcid: Yup.string().matches(
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/,
      "Please enter a valid URL"
    ),
    project_detail_link: Yup.string()
      .required("Project link is required")
      .matches(
        /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/,
        "Please enter a valid project link"
      ),
    post_youtube_link: Yup.string().matches(
      /^(https?:\/\/)?(www\.)?(youtube|youtu|youtube-nocookie)\.(com|be)\/(watch\?v=[\w-]+|embed\/[\w-]+|v\/[\w-]+|e\/[\w-]+|.+\/videoseries)/,
      "Please enter a valid YouTube URL"
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      submission_id: projectData?.submission_id || "",
      entrytype: projectData?.submission_entrytype || "",
      project_id: projectData?.project_id || "",
      project_title: projectData?.title || "",
      affiliation: projectData?.affiliation_id || "",
      orcid: projectData?.orc_id || "",
      project_detail_link: projectData?.project_detail_link || "",
      post_youtube_link: projectData?.post_youtube_link || "",
      description: projectData?.description || "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setFormBusy(true);
        const formData = new FormData();
        formData.append("submission_id", projectData.submission_id);
        if (values.first_name) formData.append("first_name", values.first_name);
        if (values.last_name) formData.append("last_name", values.last_name);
        if (entryType) formData.append("entrytype", entryType);
        if (values.project_id) formData.append("project_id", values.project_id);
        if (values.project_title)
          formData.append("title", values.project_title);
        if (values.affiliation)
          formData.append("affiliation_id", values.affiliation);
        formData.append("collaborations", JSON.stringify(collaborations));
        if (values.orcid) formData.append("orc_id", values.orcid);
        if (values.doi) formData.append("doi", values.doi);
        if (values.post_youtube_link)
          formData.append(
            "post_youtube_link",
            convertToEmbedUrl(values.post_youtube_link)
          );
        if (values.project_detail_link)
          formData.append("project_detail_link", values.project_detail_link);
        if (institution_id) formData.append("institution_id", institution_id);
        if (video_abstract) formData.append("video_abstract", video_abstract);
        if (visual_abstract)
          formData.append("visual_abstract", visual_abstract);
        if (values.description)
          formData.append("description", values.description);

        const options = {
          method: "Post",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          maxContentLength: 200 * 1024 * 1024,
          maxBodyLength: 200 * 1024 * 1024,
          body: formData,
        };

        const result = await fetchApi(apiEndpoints.UpdateProject, options);

        if (result.msg === "success") {
          toast.success("Project updated successfully.");
          navigate(`/profile`);
        } else if (result.msg === "error") {
          toast.error(result.response);
        } else if (result.errors) {
          const errorList = Object.entries(result.errors).map(
            ([field, messages]) => ` ${messages.join(", ")}`
          );
          toast.error(
            <ul>
              {errorList.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>,
            { autoClose: true }
          );
        } else {
          toast.error(result.response);
          if (result.response?.includes("Token expired.")) {
            localStorage.clear();
            Logout();
            navigate("/login");
          }
        }
      } catch (error) {
        toast.error("Something went wrong while updating the project.");
      } finally {
        setFormBusy(false);
      }
    },
  });

  const { setValues } = formik;

  useEffect(() => {
    if (projectData) {
      setValues({
        first_name: projectData.first_name || "",
        last_name: projectData.last_name || "",
        submission_id: projectData.submission_id || "",
        project_id: projectData.project_id || "",
        project_title: projectData.submission_title || "",
        entryType: projectData.submission_entrytype || "",
        affiliation: projectData.affiliation_id || "",
        orcid: projectData.orc_id || "",
        doi: projectData.doi || "",
        project_detail_link: projectData.project_detail_link || "",
        post_youtube_link: projectData.post_youtube_link || "",
        description: projectData.description || "",
      });
      setEntryType(projectData.submission_entrytype);
      setOldInstitution_id(projectData.institution_id || "");
      setOldVideo_abstract(projectData.video_abstract || "");
      setOldVisual_abstract(projectData.visual_abstract || "");
    }
  }, [projectData, setValues]);

  const handleAddCollaboration = () => {
    setCollaborations([
      ...collaborations,
      {
        collaboratortype: "",
        collaboratoraffiliation: "",
        collaborator_name: "",
        collaborator_email: "",
      },
    ]);
  };

  const handleCollaborationChange = (index, field, value) => {
    const updatedCollaborations = [...collaborations];
    updatedCollaborations[index][field] = value;
    setCollaborations(updatedCollaborations);
    console.log(collaborations);
  };

  const handleRemoveCollaboration = () => {
    const updatedCollaborations = [...collaborations];
    updatedCollaborations.pop();
    setCollaborations(updatedCollaborations);
  };

  return (
    <div className="create-project">
      <Helmet>
        <title>Edit Project - CiteEcho</title>
      </Helmet>
      <section
        id="blog-page"
        className="  inner-page-hero blog-page-section division"
      >
        <div className="container mt-2">
          <div className="row justify-content-center ">
            <div className="col-12 col-lg-10 col-xl-8 wow animate__animated animate__fadeInDown ">
              <div className="card py-4 px-1 px-lg-4">
                <h1 className="text-center h1-title ">
                  Edit Your Submit Video/Visual Abstract
                </h1>
                <br />
                <div className="row my-4">
                  <div className="col-12 col-md-4 text-center d-flex align-items-center justify-content-center">
                    <label className="label" htmlFor="new-project">
                      New Project
                    </label>
                    <input
                      value="1"
                      onChange={(e) => setEntryType(e.target.value)}
                      type="radio"
                      id="new-project"
                      name="entrytype"
                      className="mx-2"
                      checked={entryType === 1 || entryType === "1"}
                    />
                  </div>
                  <div className="col-12 col-md-4 text-center d-flex align-items-center justify-content-center">
                    <label className="label" htmlFor="add-paper">
                      Add Paper to Project
                    </label>
                    <input
                      value="2"
                      onChange={(e) => setEntryType(e.target.value)}
                      type="radio"
                      id="add-paper"
                      name="entrytype"
                      className="mx-2"
                      checked={entryType === 2 || entryType === "2"}
                    />
                  </div>
                  <div className="col-12 col-md-4 text-center d-flex align-items-center justify-content-center">
                    <label className="label" htmlFor="new-paper">
                      New Paper
                    </label>
                    <input
                      value="3"
                      onChange={(e) => setEntryType(e.target.value)} // Set value as string
                      type="radio"
                      id="new-paper"
                      name="entrytype"
                      className="mx-2"
                      checked={entryType === 3 || entryType === "3"} // Compare as string
                    />
                  </div>
                </div>
                <div className=" Fcontainer register-page">
                  <div className="text-center">
                    <p className="text-center">
                      Please fill out the following information for validation
                      and submission.
                    </p>
                  </div>
                  <form
                    className="register-page-form"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label htmlFor="first_name">
                            First Name{" "}
                            <strong className="text-danger required_label">
                              *
                            </strong>
                          </label>
                          <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            placeholder="John"
                            required=""
                            className="form-control"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.first_name &&
                          formik.errors.first_name ? (
                            <div className="error-message">
                              {formik.errors.first_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md 6">
                        <div className="form-wrap mb-2">
                          <label htmlFor="last_name">
                            Last Name{" "}
                            <strong className="text-danger required_label">
                              *
                            </strong>
                          </label>
                          <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            placeholder="Doe"
                            required=""
                            className="form-control"
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.last_name &&
                          formik.errors.last_name ? (
                            <div className="error-message">
                              {formik.errors.last_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 col-md-12">
                        <div className="form-wrap mb-2">
                          <label>
                            Affiliation{" "}
                            <strong className="text-danger required_label">
                              *
                            </strong>
                          </label>
                          <select
                            type="text"
                            id="affiliation"
                            name="affiliation"
                            placeholder="Your Affiliation"
                            required=""
                            className="form-control select"
                            value={formik.values.affiliation}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option value="">Select Affiliation</option>
                            {affiliation.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.title}
                              </option>
                            ))}
                          </select>
                          {formik.touched.affiliation &&
                          formik.errors.affiliation ? (
                            <div className="error-message">
                              {formik.errors.affiliation}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="row">
                          <div className="col-5 col-sm-3 col-md-3 col-lg-2 me-0 me-md-2">
                            <label>Collaborators</label>
                          </div>
                          <div className="col-5 col-sm-3 col-md-4 col-lg-2 me-0 me-md-2">
                            <FaPlusCircle
                              className="me-2"
                              color="green"
                              size={25}
                              onClick={handleAddCollaboration}
                            />

                            {collaborations.length > 1 && (
                              <span>
                                <FaTimesCircle
                                  color="red"
                                  size={25}
                                  onClick={handleRemoveCollaboration}
                                />
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      {collaborations.map((collaboration_item, index) => (
                        <div key={index} className="collaboration-entry">
                          <div className="row align-items-center my-2">
                            {/* Collaboration Type Select */}
                            <div className="col-12 col-md-6 mb-2">
                              <div className="form-wrap">
                                <label>
                                  Collaborator Type{" "}
                                  <strong className="text-danger required_label">
                                    *
                                  </strong>
                                </label>
                                <select
                                  id={`collaborations[${index}].collaboratortype`}
                                  name={`collaborations[${index}].collaboratortype`}
                                  value={collaboration_item.collaboratortype}
                                  onChange={(e) =>
                                    handleCollaborationChange(
                                      index,
                                      "collaboratortype",
                                      e.target.value
                                    )
                                  }
                                  className="form-control"
                                >
                                  <option value="">Select Collaboration</option>
                                  {collaboratortype.map((item) => (
                                    <option key={item.id} value={item.id}>
                                      {item.title}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            {/* Collaborator Affiliation Select */}
                            <div className="col-12 col-md-6 mb-2">
                              <div className="form-wrap">
                                <label>
                                  Collaborator Affiliation{" "}
                                  <strong className="text-danger required_label">
                                    *
                                  </strong>
                                </label>
                                <select
                                  id={`collaborations[${index}].collaboratoraffiliation`}
                                  name={`collaborations[${index}].collaboratoraffiliation`}
                                  value={
                                    collaboration_item.collaboratoraffiliation
                                  }
                                  onChange={(e) =>
                                    handleCollaborationChange(
                                      index,
                                      "collaboratoraffiliation",
                                      e.target.value
                                    )
                                  }
                                  className="form-control select"
                                >
                                  <option value="">
                                    Select Collaborator Affiliation
                                  </option>
                                  {affiliation.map((item) => (
                                    <option key={item.id} value={item.id}>
                                      {item.title}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            {/* Collaborator Name Input */}
                            <div className="col-12 col-md-6 mb-2">
                              <div className="form-wrap">
                                <label>
                                  Collaborator Name{" "}
                                  <strong className="text-danger required_label">
                                    *
                                  </strong>
                                </label>
                                <input
                                  type="text"
                                  id={`collaborations[${index}].collaborator_name`}
                                  name={`collaborations[${index}].collaborator_name`}
                                  value={collaboration_item.collaborator_name}
                                  onChange={(e) =>
                                    handleCollaborationChange(
                                      index,
                                      "collaborator_name",
                                      e.target.value
                                    )
                                  }
                                  className="form-control"
                                  placeholder="Enter Collaborator Name"
                                />
                              </div>
                            </div>

                            {/* Collaborator Email Input */}
                            <div className="col-12 col-md-6 mb-2">
                              <div className="form-wrap">
                                <label>Collaborator Email</label>
                                <input
                                  type="email"
                                  id={`collaborations[${index}].collaborator_email`}
                                  name={`collaborations[${index}].collaborator_email`}
                                  value={collaboration_item.collaborator_email}
                                  onChange={(e) =>
                                    handleCollaborationChange(
                                      index,
                                      "collaborator_email",
                                      e.target.value
                                    )
                                  }
                                  className="form-control"
                                  placeholder="Enter Collaborator Email"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label htmlFor="orcid">
                            ORCID <strong />
                          </label>
                          <input
                            type="text"
                            id="orcid"
                            name="orcid"
                            placeholder="Enter your ORCID"
                            required=""
                            className="form-control"
                            value={formik.values.orcid}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.orcid && formik.errors.orcid ? (
                            <div className="error-message">
                              {formik.errors.orcid}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label htmlFor="project_title">
                            {title}{" "}
                            <strong className="text-danger required_label">
                              *
                            </strong>
                          </label>
                          <input
                            type="text"
                            id="project_title"
                            name="project_title"
                            placeholder={"Enter the title of your " + title}
                            required=""
                            className="form-control"
                            value={formik.values.project_title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.project_title &&
                          formik.errors.project_title ? (
                            <div className="error-message">
                              {formik.errors.project_title}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {entryType === "2" && (
                        <div className="col-12 col-md-6">
                          <div className="form-wrap mb-2">
                            <label htmlFor="project_id">
                              Projects{" "}
                              <strong className="text-danger required_label">
                                *
                              </strong>
                            </label>
                            <select
                              type="text"
                              id="project_id"
                              name="project_id"
                              placeholder="Select Project to paper"
                              required=""
                              className="form-control select"
                              value={formik.values.project_id}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            >
                              <option value="">Select Project</option>
                              {myprojects.map((item) => (
                                <option
                                  key={item.submission_id}
                                  value={item.submission_id}
                                >
                                  {item.submission_title}
                                </option>
                              ))}
                            </select>
                            {formik.touched.project_id &&
                            formik.errors.project_id ? (
                              <div className="error-message">
                                {formik.errors.project_id}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}

                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label htmlFor="doi">
                            DOI <strong />
                          </label>
                          <input
                            type="text"
                            id="doi"
                            name="doi"
                            placeholder="Enter the DOI of your project/paper"
                            required=""
                            className="form-control"
                            value={formik.values.doi}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.doi && formik.errors.doi ? (
                            <div className="error-message">
                              {formik.errors.doi}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label htmlFor="project_detail_link">
                            Project Link{" "}
                            <strong className="text-danger required_label">
                              *
                            </strong>
                          </label>
                          <input
                            type="text"
                            id="project_detail_link"
                            name="project_detail_link"
                            placeholder={`Enter the project link `}
                            required=""
                            className="form-control"
                            value={formik.values.project_detail_link}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.project_detail_link &&
                          formik.errors.project_detail_link ? (
                            <div className="error-message">
                              {formik.errors.project_detail_link}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label htmlFor="post_youtube_link">
                            Post youtube link <strong />
                          </label>
                          <input
                            type="text"
                            id="post_youtube_link"
                            name="post_youtube_link"
                            placeholder="Enter the post youtube link "
                            required=""
                            className="form-control"
                            value={formik.values.post_youtube_link}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.post_youtube_link &&
                          formik.errors.post_youtube_link ? (
                            <div className="error-message">
                              {formik.errors.post_youtube_link}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label>
                            Upload your institution ID{" "}
                            <strong className="text-danger required_label">
                              *
                            </strong>
                          </label>
                          <input
                            type="file"
                            id="institution_id"
                            name="institution_id"
                            accept="image/*,application/pdf"
                            required=""
                            className="form-control-md"
                            value={formik.values.institution_id}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setInstitution_id(e.target.files[0]);
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.institution_id &&
                          formik.errors.institution_id ? (
                            <div className="error-message">
                              {formik.errors.institution_id}
                            </div>
                          ) : null}
                          <div>
                            {old_institution_id ? (
                              <a
                                href={old_institution_id}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p>View Attachment</p>
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label>
                            Upload your video abstract <strong />
                          </label>
                          <input
                            type="file"
                            id="video_abstract"
                            name="video_abstract"
                            accept="video/*"
                            required=""
                            className="form-control-md"
                            value={formik.values.video_abstract}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setVideo_abstract(e.target.files[0]);
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.video_abstract &&
                          formik.errors.video_abstract ? (
                            <div className="error-message">
                              {formik.errors.video_abstract}
                            </div>
                          ) : null}
                          <div>
                            {old_video_abstract ? (
                              <a
                                href={old_video_abstract}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p>View Attachment</p>
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="form-wrap mb-2">
                          <label>
                            Upload your visual abstract <strong />
                          </label>
                          <input
                            type="file"
                            id="visual_abstract"
                            name="visual_abstract"
                            accept="image/*"
                            required=""
                            className="form-control-md"
                            value={formik.values.visual_abstract}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setVisual_abstract(e.target.files[0]);
                            }}
                            onBlur={formik.handleBlur}
                          />

                          {formik.touched.video_abstract &&
                          formik.errors.video_abstract ? (
                            <div className="error-message">
                              {formik.errors.video_abstract}
                            </div>
                          ) : null}
                          <div>
                            {old_visual_abstract ? (
                              <a
                                href={old_visual_abstract}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <p>View Attachment</p>
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-12">
                        <div className="form-wrap mb-2">
                          <label>
                            Description <strong />
                          </label>
                          <textarea
                            id="description"
                            rows={5}
                            name="description"
                            className="form-control"
                            style={{ minHeight: 100 }}
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.description &&
                          formik.errors.description ? (
                            <div className="error-message">
                              {formik.errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-4">
                      <input
                        value="agree"
                        onChange={(e) => setAgree(e.target.value)}
                        type="radio"
                        id="agrement"
                        name="fav_language"
                        className="me-2"
                      />
                      <label htmlFor="agrement">
                        *By using our service, you agree to our
                        <Link
                          className="ms-1"
                          to={"/terms-and-conditions"}
                          target="_blank"
                          style={{ color: "#f45d08" }}
                        >
                          Terms And Conditions
                        </Link>
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <button
                          type="submit"
                          className="btn r-06 btn--theme hover--black submit w-100"
                          disabled={
                            agree.length === 0 || formBusy ? true : false
                          }
                        >
                          {formBusy ? "Please wait..." : "Submit For Review"}
                        </button>
                      </div>
                      <div className="col-12 col-md-6 mt-3 mt-md-0">
                        <button
                          type="button"
                          className="btn cancel w-100"
                          style={{ backgroundColor: "#aca8a8" }}
                          onClick={() => navigate("/profile")}
                        >
                          {"Cancel"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditProject;
