import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../context/ApiContext";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
const Verify = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");
  const { apiEndpoints, fetchApi } = useApi();
  const { token } = useParams();
  const navigate = useNavigate();
  const hasSentRequest = useRef(false);

  useEffect(() => {
    if (hasSentRequest.current) return; // Prevent duplicate requests
    hasSentRequest.current = true; // Mark as request sent

    const verifyEmail = async () => {
      setLoading(true);
      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: token }),
        };
        const result = await fetchApi(apiEndpoints.verifyAccount, options);

        if (result.msg === "success") {
          setResponse(result.response);
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          setResponse(result.response);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, []);

  return (
    <>
      <div className="px-3">{response} </div>
    </>
  );
};

export default Verify;
