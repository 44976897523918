import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./layout/Header/Header";
import Home from "./pages/Home/Home";
import Footer from "./layout/Footer/Footer";
import CreateVideo from "./pages/CreateVideo/CreateVideo";
import About from "./pages/About/About";
import Login from "./pages/Authntication/Login";
import { useLocation } from "react-router-dom";
import Signup from "./pages/Authntication/Signup";
import Forgot from "./pages/Authntication/Forgot";
import ResetPassword from "./pages/Authntication/ResetPassword";
import Profile from "./pages/Authntication/Profile";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./Utils/ProtectedRoute";
import CreateProject from "./pages/CreateProject/CreateProject";
import AuthProtected from "./Utils/AuthProtected";
import Changepassword from "./pages/Authntication/ChangePassword";
import Verify from "./pages/Authntication/Verify";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import EditProject from "./pages/CreateProject/EditProject";
import NotFound from "./pages/NotFound/NotFound";

function App() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1200);
    return () => clearTimeout(timer);
  }, []);

  const shouldHideHeader = location.pathname.includes("/verify");

  return (
    <>
      <ToastContainer />

      {loading ? (
        <div id="loading">
          <div id="loader" className="loading--theme">
            <div className="cssload-spinner" />
          </div>
        </div>
      ) : (
        <>
          {!shouldHideHeader && <Header />}
          <div id="page" className="page">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/create" element={<CreateVideo />} />
              <Route path="/about" element={<About />} />
              <Route
                path="/login"
                element={
                  <AuthProtected>
                    <Login />
                  </AuthProtected>
                }
              />
              <Route
                path="/signup"
                element={
                  <AuthProtected>
                    <Signup />
                  </AuthProtected>
                }
              />
              <Route
                path="/forgot"
                element={
                  <AuthProtected>
                    <Forgot />
                  </AuthProtected>
                }
              />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/change-password"
                element={
                  <ProtectedRoute>
                    <Changepassword />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/create-project"
                element={
                  <ProtectedRoute>
                    <CreateProject />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/edit-project/:submissionId"
                element={<EditProject />}
              />
              <Route path="/verify/:token" element={<Verify />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          {!shouldHideHeader && <Footer />}
        </>
      )}
    </>
  );
}

export default App;
