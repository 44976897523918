import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useApi } from "../../context/ApiContext";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet-async";

const Signup = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formBusy, setFormBusy] = useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const { apiEndpoints, fetchApi } = useApi();

  const validationSchema = Yup.object({
    full_name: Yup.string()
      .required("Full Name is required")
      .min(2, "Full Name must be at least 2 characters long")
      .matches(/^[^<]*$/, "Invalid Full Name"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long"),
  });

  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setFormBusy(true);
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        };
        const result = await fetchApi(apiEndpoints.userSignup, options);
        console.log(result);
        if (result.errors) {
          // Handle API validation errors
          toast.error(
            <ul>
              {Object.entries(result.errors).map(([field, messages], index) => (
                <li
                  key={index}
                  style={{ listStyleType: "none", lineHeight: 0.5 }}
                >
                  {messages.join(", ")}
                </li>
              ))}
            </ul>
          );
        } else if (result.msg === "error") {
          Swal.fire({
            // title: "Error",
            icon: "error",
            text: result.response,
            width: "370px",
            customClass: {
              popup: "popup",
              icon: "small-icon",
              confirmButton: "conferm-btn",
            },
          });

          //   navigate("/login");
        } else {
          Swal.fire({
            // title: "Error",
            icon: "success",
            text: result.response,
            width: "370px",
            customClass: {
              popup: "popup",
              icon: "small-icon",
              confirmButton: "conferm-btn",
            },
          });
          formik.resetForm();
        }
        setFormBusy(false);
      } catch (error) {
        console.log(error);
        toast.error(error);
        setFormBusy(false);
      }
    },
  });

  return (
    <div>
      <Helmet>
        <title>SignUp - CiteEcho</title>
      </Helmet>
      <div id="signup" className="register-page division  ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-5">
              <div className="register-page-form wow animate__animated animate__fadeInUp">
                <div className="register-form-title ">
                  <h1 className="h1-title">Register</h1>
                  <p>Create your account to get started</p>
                </div>
                <form
                  onSubmit={formik.handleSubmit}
                  className="row sign-up-form"
                >
                  <div className="col-md-12 mb-4">
                    <input
                      className="form-control name"
                      type="text"
                      name="full_name"
                      placeholder="Enter Your Name"
                      value={formik.values.full_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.full_name && formik.errors.full_name ? (
                      <div className="error-message">
                        {formik.errors.full_name}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-12 mb-4">
                    <input
                      className="form-control email"
                      type="email"
                      name="email"
                      placeholder="Enter Your Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="error-message">{formik.errors.email}</div>
                    ) : null}
                  </div>

                  <div className="col-md-12 mb-4">
                    <div className="wrap-input">
                      <span
                        className="btn-show-pass ico-20"
                        onClick={togglePassword}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className={` eye-pass ${
                            showPassword
                              ? "flaticon-invisible "
                              : "flaticon-visibility"
                          }`}
                        />
                      </span>
                      <input
                        className="form-control password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password (6+ characters)"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <div className="error-message">
                          {formik.errors.password}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="btn r-06 btn--theme hover--black submit"
                      disabled={formBusy}
                    >
                      {formBusy ? "Please wait..." : "Join Now"}
                    </button>
                  </div>
                  <div className="col-md-12 text-center">
                    <p className="create-account">
                      Already registered?{" "}
                      <Link to="/login" className="color--theme">
                        Log in here.
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
