import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    setToken(token);
    setUser(JSON.parse(user));
  }, []);

  const Login = (token, user) => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", token);
    setToken(token);
    setUser(user);
  };

  const Logout = () => {
    localStorage.clear();
    setUser(null);
    setToken(null);
  };

  return (
    <AuthContext.Provider value={{ user, token, Login, Logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
