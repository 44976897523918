import React, { useEffect, useState, useContext } from "react";
import { useApi } from "../../context/ApiContext";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const { apiEndpoints, fetchApi } = useApi();
  const [user, setUser] = useState({});
  const [projects, setProjects] = useState([]);
  const { token, Logout } = useContext(AuthContext);
  const [togleLoading, setTogleLoadeing] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const getMyProjects = async () => {
      setLoading(true);
      try {
        const options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const result = await fetchApi(apiEndpoints.getMyProjects, options);
        if (result.msg === "error") {
          toast.error(result.response);
          localStorage.clear();
          navigate("/login");
          Logout();
        } else {
          setProjects(result.submissions);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        localStorage.clear();
        Logout();
        navigate("/login");
      }
    };

    const getProfile = async () => {
      try {
        setLoading(true);
        const options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const result = await fetchApi(apiEndpoints.userProfile, options);

        if (result.response.includes("Token expired.")) {
          toast.error(result.response);
          localStorage.clear();
          Logout();
          navigate("/login");
        } else {
          setUser(result.user);
        }
        setLoading(false);
      } catch (error) {
        toast.error("Something went wrong");
        setLoading(false);
      }
    };

    getProfile();
    getMyProjects();
  }, [token, fetchApi, apiEndpoints.getMyProjects, apiEndpoints.userProfile, Logout, navigate]);

  const handleVisibilityStatus = async (projectId, visibilityStatus) => {
    setTogleLoadeing(true);
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          project_id: projectId,
          visibility_status: visibilityStatus,
        }),
      };

      const result = await fetchApi(
        apiEndpoints.UpdateProjectVisibility,
        options
      );

      if (result.msg === "error") {
        toast.error(result.response);
      } else {
        toast.success(result.response);
        setProjects((prevProjects) =>
          prevProjects.map((project) =>
            project.submission_id === projectId
              ? { ...project, visibility_status: visibilityStatus }
              : project
          )
        );
      }
    } catch (error) {
      console.error("Error during changing visibility status:", error);
      toast.error("An error occurred while submitting the request.");
    } finally {
      setTogleLoadeing(false);
    }
  };

  const handleEditPage = (submissionId) => {
    navigate(`/edit-project/${submissionId}`);
  };

  const getFirst20Words = (text) => {
    if (!text) {
      return ""; // Return empty string if text is null or undefined
    }
    const words = text.split(" ");
    return words.length > 20 ? words.slice(0, 20).join(" ") + "..." : text;
  };

  return (
    <div>
      <Helmet>
        <title>Profile - CiteEcho</title>
      </Helmet>
      <section className="bg-light profile">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb-4 mb-sm-5">
              <div className="border-0">
                <div className="card-body">
                  <div className="row g-0 align-items-center">
                    <div className="col-lg-6 mb-4 mb-lg-0">
                      <div className="d-flex align-items-center">
                        <div className="avatar-container me-2">
                          <img
                            src="/assets/images/user.png"
                            alt="..."
                            className="profile-avatar"
                          />
                        </div>
                        <div className="py-1-9 w-100 rounded">
                          <h3 className="h5 mb-0">{user?.full_name}</h3>
                          <span>{user?.email}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 mb-2 mb-sm-2">
                    <hr />
                    <div className="mt-0">
                      <div className="d-flex align-items-center justify-content-between">
                        <h3 className="h3-lg">Projects/Papers</h3>
                        <button
                          onClick={() => navigate("/create-project")}
                          className="btn r-06 btn--theme hover--black submit"
                        >
                          Create New
                        </button>
                      </div>
                      <div
                        className={
                          projects.length === 0
                            ? "mt-3 row gx-2 row-cols-1 row-cols-md-1 row-cols-lg-1"
                            : "mt-3 row gx-2 row-cols-1 row-cols-md-2 row-cols-lg-3"
                        }
                      >
                        {loading ? (
                          <div className="row justify-content-center">
                            <div className="col-12 text-center">
                              <div className="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        ) : projects.length === 0 ? (
                          <div className="row justify-content-center">
                            <div className="col-12 text-start">
                              <p>
                                No records found. Click the "Create New" button
                                to submit a project/paper.
                              </p>
                            </div>
                          </div>
                        ) : (
                          projects.map((project) => (
                            <div
                              className="col mb-4"
                              key={project.submission_id}
                            >
                              <div className="blog-post wow animate__animated animate__fadeInUp h-100">
                                <div className="blog-post-img r-12">
                                  {project.video_abstract ? (
                                    <video
                                      src={project.video_abstract}
                                      className="card_image"
                                      controls
                                    >
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : project.post_youtube_link ? (
                                    <iframe
                                      src={project.post_youtube_link}
                                      className="card_image"
                                      width="100%"
                                      title={project.project_title}
                                    />
                                  ) : project.visual_abstract ? (
                                    <img
                                      src={project.visual_abstract}
                                      alt={project.project_title}
                                      width="100%"
                                      className="card_image"
                                    />
                                  ) : (
                                    <p>No media available</p>
                                  )}
                                </div>
                                <div className="blog-post-txt pb-3">
                                  <p className="pb-2">
                                    <strong>Type:</strong>{" "}
                                    {project.entrytype === 1
                                      ? "Project"
                                      : project.entrytype === 2
                                      ? "Paper to Project"
                                      : "Paper"}
                                  </p>
                                  <p className="pb-2">
                                    <strong>Status:</strong>{" "}
                                    {project.status === 1
                                      ? "Approved"
                                      : "Pending"}
                                  </p>
                                  <h6 className="post_title">
                                    <a
                                      href={project.project_detail_link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {project.submission_title}
                                    </a>
                                  </h6>
                                  <p className="pb-2">
                                    {getFirst20Words(project.description)}
                                  </p>
                                  <p className="pb-2">
                                    <strong>Affiliation:</strong>{" "}
                                    {project.affiliation_name}
                                  </p>
                                  <div className="row my-2">
                                    <div className="col-sm-6">
                                      <button
                                        type="button"
                                        className="btn r-06 btn--theme hover--black button edit-btn"
                                        onClick={() =>
                                          handleEditPage(project.submission_id)
                                        }
                                      >
                                        <i className="fas fa-edit"></i> Edit
                                      </button>
                                    </div>
                                    <div className="col-sm-6">
                                      <button
                                        onClick={() =>
                                          handleVisibilityStatus(
                                            project.submission_id,
                                            project.visibility_status === 1
                                              ? 0
                                              : 1
                                          )
                                        }
                                        className={`btn r-06 btn--theme hover--black button ${
                                          project.visibility_status === 1
                                            ? "togle_hide"
                                            : "btn-info"
                                        }`}
                                        disabled={togleLoading}
                                      >
                                        <i className="fas fa-eye"></i>{" "}
                                        {project.visibility_status === 1
                                          ? "Hide From Feed"
                                          : "Visible In Feed"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Profile;
