import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./Header.css";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { user, token, Logout } = useContext(AuthContext);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const navMenu = document.querySelector(".wsmainfull");

      if (scrollPosition > 80) {
        navMenu?.classList.add("scroll");
      } else {
        navMenu?.classList.remove("scroll");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOnMenuItem = () => {
      setIsMenuOpen(false);
      document.body.classList.remove("wsactive");
    };

    if (window.outerWidth < 992) {
      const menuItems = document.querySelectorAll(
        ".wsmenu-list li.nl-simple, .wsmegamenu li, .sub-menu li.h-link"
      );
      menuItems.forEach((item) =>
        item.addEventListener("click", handleClickOnMenuItem)
      );

      return () => {
        menuItems.forEach((item) =>
          item.removeEventListener("click", handleClickOnMenuItem)
        );
      };
    }
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev); // Toggle menu open/close state
    document.body.classList.toggle("wsactive", !isMenuOpen);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <header id="header" className="tra-menu navbar-dark white-scroll">
      <div className="header-wrapper">
        <div className="wsmobileheader clearfix">
          <div className="container d-flex">
            <span className="smllogo d-flex align-items-center">
              <a
                className="d-flex align-items-center"
                onClick={() => navigate("/")}
              >
                <img
                  className="lt-img"
                  src="/assets/images/logo.png"
                  alt="mobile-logo"
                />
                <h6 className="ps-3">CiteEcho</h6>
              </a>
            </span>
            <a
              id="wsnavtoggle"
              className="wsanimated-arrow"
              onClick={toggleMenu}
            >
              <span />
            </a>
          </div>
        </div>

        <div className={`wsmainfull menu clearfix ${isMenuOpen ? "open" : ""}`}>
          <div className="wsmainwp ">
            <div className="desktoplogo">
              <a
                onClick={() => navigate("/")}
                className="logo-black d-flex align-items-center pt-2"
              >
                <img
                  className="lt-img"
                  src="/assets/images/logo.png"
                  alt="logo"
                />
                <h5 className="ps-3">CiteEcho</h5>
              </a>
            </div>

            <nav className="wsmenu clearfix">
              <div class="overlapblackbg"></div>
              <ul className="wsmenu-list nav-theme">
                <li
                  className={`nl-simple ${isActive("/") ? "active" : ""}`}
                  aria-haspopup="true"
                >
                  <a onClick={() => navigate("/")} className="h-link">
                    Discover
                  </a>
                </li>
                <li
                  className={`nl-simple ${isActive("/create") ? "active" : ""}`}
                  aria-haspopup="true"
                >
                  <a onClick={() => navigate("/create")} className="h-link">
                    Create
                  </a>
                </li>
                <li
                  className={`nl-simple ${isActive("/about") ? "active" : ""}`}
                  aria-haspopup="true"
                >
                  <a onClick={() => navigate("/about")} className="h-link">
                    About
                  </a>
                </li>
                {!token || token === null ? (
                  <li
                    className={`nl-simple ${
                      isActive("/login") ||
                      isActive("/signup") ||
                      isActive("/forgot")
                        ? "active"
                        : ""
                    }`}
                    aria-haspopup="true"
                  >
                    <a onClick={() => navigate("/login")} className="h-link">
                      Login/Register
                    </a>
                  </li>
                ) : (
                  <li
                    aria-haspopup="true"
                    onClick={() => setShowMenu(!showMenu)}
                  >
                    <span className="wsmenu-click">
                      <i className="wsmenu-arrow" />
                    </span>
                    <a href="javascript:void(0)" className="h-link   ">
                      <div>Dashboard</div>
                      <div>
                        <span className="wsarrow d-block " />
                      </div>
                    </a>
                    <ul
                      className={`sub-menu ${
                        showMenu ? "visible" : "invisible"
                      } ico-10 mt-3 mt-md-0`}
                      style={{ display: showMenu ? "block" : "none" }}
                    >
                      <div className="info-container py-2 px-3 text-start ">
                        <div style={{ fontWeight: "700" }}>
                          {user.full_name}
                        </div>
                        <div>{user.email}</div>
                      </div>
                      <li aria-haspopup="true" className="h-link">
                        <Link to="/profile">Profile</Link>
                      </li>
                      <li aria-haspopup="true" className="h-link">
                        <Link to="/change-password">Change password</Link>
                      </li>
                      <li aria-haspopup="true" className="h-link">
                        <a onClick={Logout}>Logout</a>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
