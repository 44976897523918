import React from "react";
import './Footer.css'
import { useNavigate } from "react-router-dom";

const Footer = () => {

  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  return (
    <>
      <hr className="divider" />
      <footer className="py-3 footer-3 footer division">
        <div className="container">
          <div className="bottom-footer">
            <div className="row row-cols-1 row-cols-md-1 d-flex align-items-center">
              <div className="col">
                <div className="footer-copyright text-center">
                  <p className="p-sm">
                    © {currentYear} <a className="link_site" onClick={() => navigate("/")}>CiteEcho.</a>{" "}
                    <span>All rights reserved.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
